import React from 'react';
import { Modal, Accordion, Container, Text } from '@mantine/core';
import { X, Check } from 'tabler-icons-react';
import moment from 'moment';

export const Logs = ({ logsModal, setLogsModal }) => {
  const { logs = [], type, open } = { ...logsModal };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

   // Sort logs by createdAt or manually_closed_at in ascending order
   const sortedLogs = [...logs].sort((a, b) => {
    const dateA = new Date(a.manually_closed_at || a.createdAt).getTime();
    const dateB = new Date(b.manually_closed_at || b.createdAt).getTime();
    return dateA - dateB;
  });

  return (
    <Modal
      opened={open}
      onClose={() => setLogsModal({ open: false, logs: [], type: null })}
      title='Logs'
      className='confirm-modal'
    >
      <Container>
        <Accordion
          defaultValue={'key_0'}
          overflow='inside'
          iconPosition='right'
          classNames={{
            label: 'accordion-label fw-bold',
            contentInner: 'accordion-innerContent',
            control: 'p-0',
          }}
        >
          <Accordion.Item className='scope-implementation-label' value='key_0'>
            <Accordion.Control>{`${capitalizeFirstLetter(type)}`}</Accordion.Control>
            <Accordion.Panel>
              {sortedLogs.length > 0 ? (
                sortedLogs.map((log, index) => {
                  return (
                    <div key={index}>
                      <Text>
                        <span style={{ fontWeight: 'bold' }}>Step: </span>
                        {capitalizeFirstLetter(type)}
                        <span style={{ paddingLeft: '0.5rem' }}>
                          {!log.status || String(log.status).toLowerCase() === 'in progress' ? (
                            <X color='red' size='1rem' />
                          ) : (
                            <Check color='green' size='1rem' />
                          )}
                        </span>
                      </Text>
                      <Text>
                        <span style={{ fontWeight: 'bold' }}>Timestamp: </span>
                        {log.manually_closed_at
                          ? `${moment(log.manually_closed_at).utcOffset(330).format('DD-MM-YYYY HH:mm:ss')} (IST), ${moment(log.manually_closed_at).utcOffset(0).format('DD-MM-YYYY HH:mm:ss')} (GMT)`
                          : log.createdAt
                          ? `${moment(log.createdAt).utcOffset(330).format('DD-MM-YYYY HH:mm:ss')} (IST), ${moment(log.createdAt).utcOffset(0).format('DD-MM-YYYY HH:mm:ss')} (GMT)`
                          : log.created_at
                          ? `${moment(log.created_at).utcOffset(330).format('DD-MM-YYYY HH:mm:ss')} (IST), ${moment(log.created_at).utcOffset(0).format('DD-MM-YYYY HH:mm:ss')} (GMT)`
                          : 'Not Started'}
                      </Text>
                      {!log.bapi_status && (
                      <Text>
                        <span style={{ fontWeight: 'bold' }}>Remark: </span>
                        { !log.status || String(log.status).toLowerCase() === 'in progress'
                          ? log.remark
                          : log.status}
                      </Text>
                      )} 
                      {log.bapi_status ? (
                        <div>
                          <Text>
                            <span style={{ fontWeight: 'bold' }}> Status: </span>
                            <Text component="span" color={log.bapi_status === 'Fail' ? 'red' : 'green'}>
                            {log.bapi_status}
                           </Text>
                          </Text>
                          <Text>
                            <span style={{ fontWeight: 'bold' }}> Message: </span>
                            {Array.isArray(log.bapi_message) ? (
                              log.bapi_message.map((messageObj, i) => (
                                <div key={i}>
                                  <Text>
                                    <span style={{ fontWeight: 'lighter' }}>Status: </span>
                                    {messageObj.status} - <span>{messageObj.message}</span>
                                  </Text>
                                </div>
                              ))
                            ) : (
                              <span>{log.bapi_message}</span>
                            )}
                          </Text>
                        </div>
                      ) : (
                        <div></div>
                      )} <br />
                      {log.manually_closed_by ? (
                        <div>
                          <Text>
                            <span style={{ fontWeight: 'bold' }}>Manually closed by: </span>
                            {log.manually_closed_by}
                          </Text>
                          {log.manually_closed_comment && (
                            <Text>
                              <span style={{ fontWeight: 'bold' }}>Manually closed comment: </span>
                              {log.manually_closed_comment}
                            </Text>
                          )}
                        </div>
                      ) : (
                        <div></div>
                      )}
                      <br />
                    </div>
                  );
                })
              ) : (
                <div>
                  <Text>
                    <span style={{ fontWeight: 'bold' }}> No Logs Found </span>
                  </Text>
                </div>
              )}
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Container>
    </Modal>
  );
};
