import React, { createContext, useCallback, useState, useEffect, useContext } from 'react';
import { axios } from '../utils/axios';
import delistingFormSchema from '../pages/DLST/ScopeCompDLST/validation';
import { RfsContext } from './RfsContext';

export const DelistingContext = createContext();

export const DelistingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [marketLoading, setMarketLoading] = useState(true);
  const [delistingForm, setDelistingForm] = useState({});
  const [selectedMarkets, setSelectedMarkets] = useState({});
  const [obsoletes, setObsoletes] = useState({});
  const [scopeErrors, setScopeErrors] = useState({
    errors: {},
    delistingErrors: {},
  });

  const fetchDelisting = useCallback(
    (rfs_number) => {
      setLoading(true);

      axios
        .get(`/delisting/fetch/${rfs_number}`)
        .then(
          (res) => {
            const currentValues = { ...delistingForm };
            res.data.data.forEach((item) => {
              currentValues[item.scope_id] = item;

              if (!item.impacted_markets) currentValues[item.scope_id]['impacted_markets'] = [];
            });

            setDelistingForm(currentValues);
          },
          (error) => {
            console.log(error);
          },
        )
        .finally(() => setLoading(false));

      axios.get(`/delisting/obsoletes/${rfs_number}`).then(
        (res) => {
          const currentObsoletes = { ...obsoletes };
          res.data.data.forEach((item) => {
            if (currentObsoletes.hasOwnProperty(item.scope_id))
              currentObsoletes[item.scope_id].push(item);
            else currentObsoletes[item.scope_id] = [item];
          });

          setObsoletes(currentObsoletes);
        },
        (error) => {
          console.log(error);
        },
      );
    },
    [setDelistingForm, setObsoletes],
  );

  const fetchSelectedMarkets = useCallback(
    (rfs_number, delistingForm) => {
      const promises = Object.keys(delistingForm).map((scope_id) => {
        return axios
          .get(`/delisting/selectedMarkets/${rfs_number}/${scope_id}`)
          .then((res) => {
            const currentMarkets = { ...selectedMarkets };
            res.data.markets.forEach((item) => {
              if (currentMarkets.hasOwnProperty(scope_id)) currentMarkets[scope_id].push(item);
              else currentMarkets[scope_id] = [item];
            });

            return currentMarkets;
          })
          .catch((error) => {
            console.log(error);
            return {};
          });
      });

      Promise.all(promises)
        .then((results) => {
          const convertedMarkets = results.reduce((result, obj) => {
            const [scopeId] = Object.keys(obj);
            const markets = obj[scopeId];
            result[scopeId] = markets;
            return result;
          }, {});

          setSelectedMarkets(convertedMarkets);
        })
        .finally(() => setMarketLoading(false))
        .catch((error) => {
          console.log(error);
        });
    },
    [setSelectedMarkets],
  );

  const validateDelisting = (data = {}) => {
    const errors = {};
    const delistingFieldErrors = {};

    for (let item of Object.values(
      Object.keys(data).length > 0 ? { ...data } : { ...delistingForm },
    )) {
      delistingFormSchema
        .validate({ ...item }, { abortEarly: false })
        .then(() => {})
        .catch((err) => {
          const fieldErrors = err.errors;
          fieldErrors.forEach((error) => {
            let [err] = error.split(' ');
            errors[item.scope_id] = true;

            if (!delistingFieldErrors[item.scope_id]) delistingFieldErrors[item.scope_id] = {};

            delistingFieldErrors[item.scope_id][err] = true;
          });
        });
    }

    return { errors, delistingErrors: delistingFieldErrors };
  };

  return (
    <DelistingContext.Provider
      value={{
        loading,
        delistingForm,
        setDelistingForm,
        selectedMarkets,
        setSelectedMarkets,
        obsoletes,
        setObsoletes,
        fetchDelisting,
        fetchSelectedMarkets,
        validateDelisting,
        scopeErrors,
        setScopeErrors,
        marketLoading,
      }}
    >
      {children}
    </DelistingContext.Provider>
  );
};
