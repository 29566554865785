import { useContext, useEffect, useState } from 'react';
import VLCTable from '../../costConditionCreate/vlcSetup/table';
import { axios } from '../../../../../utils/axios';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { showAlert } from '../../../../../utils/alerts';
import { CostingRequestsContext } from '../../../../../contexts/CostingRequest';

const FobSetup = ({ activeTab, activeSubTab, logsModal, setLogsModal }) => {
  const { crNumber } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { getIntCrUserRoleMappings, userRoleMappings } = useContext(CostingRequestsContext);

  useEffect(() => {
    const fetchMappings = async () => {
      await getIntCrUserRoleMappings();
    };
    fetchMappings();
  }, []);

  useEffect(() => {
    if (activeTab === 'tpSetup' && activeSubTab === 'fobSetup' && userRoleMappings.length > 0) {
      fetch();
    }
  }, [activeTab, activeSubTab, userRoleMappings]);

  const getAllowedCountries = (role) => {
    if (role === 'fpna') {
      let countries = userRoleMappings
        .filter((role) => role.country && role.is_active && role.int_role?.name === 'GCC FP&A')
        .map((role) => role.country);

      countries = [...new Set(countries)];
      return countries;
    }

    let logistics = userRoleMappings
      .filter(
        (role) =>
          role.country && role.is_active && role.int_role?.alias === 'logistics_fpna_analyst',
      )
      .map((role) => role.country);

    logistics = [...new Set(logistics)];
    return logistics;
  };
  const checkActive = (status, country, userType) => {
    const allowedCountries = getAllowedCountries(userType);
    const res =
      String(status).toLocaleLowerCase() === 'in progress' && allowedCountries.includes(country);

    return res ?? false;
  };
  const checkActiveFpna = (status, country, value) => {
    const allowedCountries = getAllowedCountries('fpna');
    const res =
      String(status).toLocaleLowerCase() === 'in progress' &&
      allowedCountries.includes(country) &&
      parseFloat(value) > 0;

    return res ?? false;
  };

  const transformData = (data) => {
    const newData = data.map((ele) => {
      ele.sku = ele?.IntCrConditionRecordLogs?.[0]?.material;
      ele.sku_desc = ele?.IntCrConditionRecordLogs?.[0]?.material_description;
      ele.status = ele?.status || ele?.IntCrConditionRecordLogs?.[0]?.status;
      ele.producing_plant = ele?.producing_plant;
      ele.sales_org = ele?.IntCrConditionRecordLogs?.[0]?.sales_org;
      ele.distribution_channel = ele?.IntCrConditionRecordLogs?.[0]?.distribution_channel;
      ele.condition_type = ele?.IntCrConditionRecordLogs?.[0]?.condition_type;
      ele.key_combination = ele?.IntCrConditionRecordLogs?.[0]?.key_combination;
      ele.costPerHL = ele?.IntCrConditionRecordLogs?.[0]?.cost_per_hl;
      ele.costPerHundred = ele?.IntCrConditionRecordLogs?.[0]?.cost_per_100hl;
      ele.currency = ele?.IntCrConditionRecordLogs?.[0]?.currency_key;
      ele.uom = ele?.IntCrConditionRecordLogs?.[0]?.unit_of_measure;
      ele.unit = ele?.IntCrConditionRecordLogs?.[0]?.per;
      ele.valid_from = moment(ele?.IntCrConditionRecordLogs?.[0]?.valid_from).isValid()
        ? moment(ele?.IntCrConditionRecordLogs?.[0]?.valid_from).format('DD-MM-YYYY')
        : null;
      ele.valid_to = moment(ele?.IntCrConditionRecordLogs?.[0]?.valid_to).isValid()
        ? moment(ele?.IntCrConditionRecordLogs?.[0]?.valid_to)
            .utc()
            .startOf('day')
            .format('DD-MM-YYYY')
        : null;
      ele.submittedOn = moment(ele?.IntCrConditionRecordLogs?.[0]?.createdAt).isValid()
        ? moment(ele?.IntCrConditionRecordLogs?.[0]?.createdAt).format('DD-MM-YYYY')
        : null;
      ele.completedOn = moment(ele?.IntCrConditionRecordLogs?.[0]?.completed_on).isValid()
        ? moment(ele?.IntCrConditionRecordLogs?.[0]?.completed_on).format('DD-MM-YYYY')
        : null;
      ele.fpna_gcc_sme = ele?.FPnA_sme?.name;
      ele.logisticsFpna = ele?.LogisticsFpNa_sme?.name;
      return ele;
    });
    return newData;
  };
  const fetch = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`costing-request/step/fob_setup/${crNumber}`);
      const apiData = transformData(res?.data?.data);
      setData(apiData);
    } catch (e) {
      console.log('errorrr', e);
      showAlert(e, 'error');
    } finally {
      setLoading(false);
    }
  };
  const handdleCostChnage = async (val, id) => {
    setLoading(true);
    const payload = {
      step_id: id,
      amount: val,
    };
    try {
      const res = await axios.post(`costing-request/step/fob_setup/${crNumber}`, payload);
      if (res?.data?.success) {
        showAlert({ message: 'Cost per HL updated' }, 'success');
      }
    } catch (e) {
      console.log(e);
      showAlert(e, 'error');
    } finally {
      setLoading(false);
    }
  };
  const handleRefresh = async (id) => {
    setLoading(true);
    const payload = { step_id: id };
    try {
      const res = await axios.post(`costing-request/step/fob_setup/${crNumber}`, payload);
      if (res?.success) {
        showAlert('', 'success');
      }
    } catch (e) {
      showAlert(e, 'error');
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <VLCTable
        handleRefresh={handleRefresh}
        data={data}
        handdleCostChnage={handdleCostChnage}
        loading={loading}
        logsModal={logsModal}
        setLogsModal={setLogsModal}
        logsHeader={'Fob Setup'}
        checkActive={checkActive}
        checkActiveFpna={checkActiveFpna}
        csvFilename={`fob_setup_${moment().format('YYYY-MM-DD')}`}
      />
    </>
  );
};

export default FobSetup;
