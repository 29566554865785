import { Space, Tabs } from '@mantine/core';
import { tabStyles } from '../../../../utils/helpers';
import FobSetup from './fobSetup';
import { ProjectOverview } from '../projectOverview';
import { useSearchParams } from 'react-router-dom';
import { TpErpSetup } from './tpErpSetup';
import { UpdateSKU } from './updateSku';
import { UpdateVLC } from './updateVlc';
import { CreateTPFlow } from './createTpFlow';
import FinalizeTPFlow from './finalizeTpFlow';

const TpSetups = ({ activeTab, logsModal, setLogsModal }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeSubTab = searchParams.get('subtab') || 'updateSku';
  const tpSetupTabs = [
    {
      name: 'Update SKU ',
      value: 'updateSku',
      component: (props) => <UpdateSKU {...props} />,
    },
    {
      name: 'Update VLC',
      value: 'updateVlc',
      component: (props) => <UpdateVLC {...props} />,
    },
    {
      name: 'Create TP Flow',
      value: 'createTpFlow',
      component: (props) => <CreateTPFlow {...props} />,
    },
    {
      name: 'FOB Setup',
      value: 'fobSetup',
      component: (props) => <FobSetup {...props} />,
    },
    {
      name: 'Finalize TP Flow',
      value: 'finalizeTpFlow',
      component: (props) => <FinalizeTPFlow {...props} />,
    },
    {
      name: 'TP ERP Setup',
      value: 'tpErpSetup',
      component: (props) => <TpErpSetup {...props} />,
    },
  ];
  return (
    <>
      <Space h='sm' />

      <Tabs
        variant='unstyled'
        styles={tabStyles}
        className='prod-dev-tabs'
        color='#e5b611'
        onTabChange={(value) => setSearchParams({ tab: activeTab, subtab: value })}
        value={activeSubTab}
        keepMounted={false}
      >
        <Tabs.List>
          {tpSetupTabs.map((tab, index) => (
            <Tabs.Tab value={tab.value} key={`tp_${index}`} className='menu-tabs'>
              {tab.name}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        {tpSetupTabs.map((tab, index) => (
          <>
            <Tabs.Panel value={tab.value} key={`tp_panel_${index}`}>
              <ProjectOverview />
              <Space h='sm' />
              {tab.component({ activeTab, activeSubTab, logsModal, setLogsModal })}
            </Tabs.Panel>
          </>
        ))}
      </Tabs>
    </>
  );
};

export default TpSetups;
