import moment from 'moment';
import { useEffect, useMemo, useState,useContext } from 'react';
import { Button } from '@mantine/core';
import MantineTable from '../../../../../common/table/mantine';
import { axios } from '../../../../../utils/axios';
import { useParams } from 'react-router-dom';
import { Status } from '../../../styles';
import styled from '@emotion/styled';
import { IconRefresh, IconTrash } from '@tabler/icons-react';
import { showAlert } from '../../../../../utils/alerts';
import { CostingRequestsContext } from '../../../../../contexts/CostingRequest';
export const CreateTPFlow = ({ setLogsModal, activeTab,activeSubTab }) => {
  const { crNumber } = useParams();
  const [loading, setLoading] = useState(false);
  const [skus, setSkus] = useState([]);
  const { crUserPermissions } = useContext(CostingRequestsContext);
  const CenterItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
  useEffect(() => {
    if (activeTab === 'tpSetup' && activeSubTab === 'createTpFlow') {
        fetch();
    }
  }, [activeTab,activeSubTab]);
  const fetch = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/costing-request/step/tp_flow/${crNumber}`);
      const responseData = response.data.data;
      const data = responseData.IntCrTpRecords.map((tpRecord) => {
        const status = (tpRecord.status || '').toLowerCase();
        
        return {
          sku_number: tpRecord.sku,
          sku_description: tpRecord.sku_description,
          producing_plant: responseData.int_cr_producing_plant.producing_plant,
          producing_country: tpRecord.producing_country,
          buying_country: tpRecord.buying_country,
          avic_hl: tpRecord.cost_per_hl || 'N/A',
          indicativeDb: tpRecord.indicative_database,
          status: status === 'not started'
            ? 'Not Started'
            : status === 'closed'
            ? 'Completed'
            : 'Pending',
          logs: tpRecord.logs,
          assignedTo: responseData.costing_ticket_assigned?.name || 'N/A',
          spoc: responseData.costing_ticket_assigned?.name || 'N/A',
          step_id: responseData.step_id,
          sku_id: responseData.sku_id,
          producing_plant_id: responseData.producing_plant_id,
          row_id: tpRecord.id
        };
      });
      setSkus(data);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = async (row_id) => {
    try {
      setLoading(true);
      const payload = {
        row_id :row_id
      };
      console.log("payload", payload)
      const response = await axios.post(`/costing-request/step/tp_flow_retry/${crNumber}`, payload);
  
      if (response.status === 200) {
        showAlert(
          {
            message: 'TP Flow submitted successfully in Anaplan',
          },
          'success'
        );
      } else {
        throw new Error(response.data.message || 'Failed to refresh costing request');
      }
      await fetch();
    } catch (error) {
      showAlert(
        {
          message: 'TP Submission failed in Anaplan. Please check logs',
        },
        'error'
      );
    }
   finally {
    setLoading(false);
  }
  };
  
const columns = useMemo(
  () => [
    {
      accessorKey: 'action',
      header: 'Action',
      Cell: ({ row }) => {
        const plant =row.original?.producing_plant;
        const status = row.original?.status
        const rowId = row.original?.row_id
        const hasRefreshAccess =crUserPermissions[String(plant)]?.['standard/transfer_pricing']
                                ?.is_accessible;
        const isDisabled = status === 'Completed' || !hasRefreshAccess || status ==='Not Started';
        return (
          <Button
            variant="subtle"
            color="dark"
            size="sm"
            style={{ width: '100%' }}
            onClick={() => handleRefresh( rowId)}
            disabled={isDisabled}
          >
            <IconRefresh color='black' variant='filled' size={16} />
          </Button>
        );
      },
    },
    {
      accessorKey: 'sku_number',
      header: 'SKU No',
    },
    {
      accessorKey: 'sku_description',
      header: 'SKU Desc',
    },
    {
      accessorKey: 'producing_country',
      header: 'Producing Country',
    },
    {
      accessorKey: 'buying_country',
      header: 'Buying Country',
    },
    {
        accessorKey: 'avic_hl',
        header: 'AVIC/HL',
    },
    {
      accessorKey: 'indicativeDb',
      header: 'Indicative DB',
    },
    {
      id: 'status',
      header: 'Status',
      Cell: ({ row }) => <Status status={row.original.status} />,
    },
    {
        id: 'logs',
        header: 'Logs',
        Cell: ({ row }) => (
          <Button
            color='dark'
            onClick={() =>
              setLogsModal({
                open: true,
                logs: row.original.logs,
                type: 'Create TP Flow Check',
              })
            }
          >
            View Logs
          </Button>
        ),
      },
    {
      accessorKey: 'assignedTo',
      header: 'FP&A GCC SME',
    },
  ],
  [skus]
);
return (
  <>
    <MantineTable
      columns={columns}
      initialData={skus}
      unique="sku" 
      enableRowSelection={false}
      loading={loading}
      showSelectedToggle={false}
      hideSelectColumn={true}
      showResetAll={false}
      enableExport={true}
      csvFilename={`create_tpflow_check_${moment().format('YYYY-MM-DD')}`}
      pageSize={10}
    />
  </>
);
};
