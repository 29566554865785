import { useMemo } from 'react';
import MantineTable from '../../../../../common/table/mantine';
import { Status } from '../../../styles';
import { Button, NumberInput } from '@mantine/core';
import moment from 'moment';

const FinalizeTable = ({ data, loading, initialState, handleChange, setLogsModal, finalizeTp }) => {
  const columns = useMemo(() => [
    {
      accessorKey: 'version',
      header: 'Version',
    },
    {
      accessorKey: 'control_number',
      header: 'Control Number',
    },
    {
      accessorKey: 'updated_by',
      header: 'Updated By',
    },
    {
      accessorKey: 'producing_zone',
      header: 'Producing Zone',
    },
    {
      accessorKey: 'producing_country',
      header: 'Producing Country',
    },
    {
      accessorKey: 'manufacturing_service',
      header: 'Manufacturing Service ',
    },
    {
      accessorKey: 'manufacturing_entity_name',
      header: 'Manufacturing Entity Name',
    },
    {
      accessorKey: 'sales_org_erp',
      header: 'Sales Org(ERP)',
    },
    {
      accessorKey: 'manufacturing_plant',
      header: 'Manufacturing Plant',
    },
    {
      accessorKey: 'buying_zone',
      header: 'Buying Zone',
    },
    {
      accessorKey: 'intermediary_country_elbe_be',
      header: 'Intermediary Country Elbe(BE)',
    },
    {
      accessorKey: 'buying_country',
      header: 'Buying Country',
    },
    {
      accessorKey: 'brand',
      header: 'Brand',
    },
    {
      accessorKey: 'pack_type',
      header: 'Pack Type',
    },
    {
      accessorKey: 'erp_sku_nr',
      header: 'ERP SKU nr.',
    },
    {
      accessorKey: 'sku_name',
      header: 'SKU Name',
    },
    {
      accessorKey: 'incoterm',
      header: 'Incoterm',
    },
    {
      accessorKey: 'curr',
      header: 'Curr',
    },
    {
      accessorKey: 'vlc',
      header: 'VLC',
    },
    {
      accessorKey: 'vplc',
      header: 'VPLC',
    },
    {
      accessorKey: 'fob',
      header: 'FOB',
    },
    {
      accessorKey: 'leffe_royalties',
      header: 'Leffe Royalties',
    },
    {
      accessorKey: 'vilc_interco_in_perf',
      header: 'VILC interco (in perf)',
    },
    {
      accessorKey: 'fixed_costs',
      header: 'Fixed Costs',
    },
    {
      accessorKey: 'dep',
      header: 'Dep',
    },
    {
      accessorKey: 'royalties',
      header: 'Royalties',
    },
    {
      accessorKey: 'std_logistics_costs',
      header: 'Std Logistics Costs',
      Cell: ({ row }) => {
        return (
          <NumberInput
            min={0}
            max={1000000}
            value={row?.original?.std_logistics_costs}
            onChange={(value) => handleChange({ id: row.original?.id, std_logistics_costs: value || 0 })}
          />
        );
      },
    },
    {
      accessorKey: 'submitted_on',
      header: 'Submitted On',
    },
    {
      accessorKey: 'other_intangible_compensation',
      header: 'Other Intangible Compensation',
    },
    {
      accessorKey: 'mu_percent',
      header: 'MU%',
    },
    {
      accessorKey: 'std_mu',
      header: 'Std MU',
    },
    {
      accessorKey: 'interco_markup_out_of_perf',
      header: 'Interco Markup (out of perf)',
    },
    {
      accessorKey: 'total_tp',
      header: 'Total TP',
    },
    {
      accessorKey: 'action',
      header: 'Action',
      Cell: ({ row }) => (
        <Button
          color='dark'
          onClick={() => {
            finalizeTp({ id: '', val: '' });
          }}
        >
          Finalize TP
        </Button>
      ),
    },
    {
      accessorKey: 'status',
      header: 'Status',
      Cell: ({ row }) => {
        return row.original?.status ? <Status status={row.original?.status} /> : null;
      },
    },
    {
      accessorKey: 'logs',
      header: 'Logs',
      Cell: ({ row }) => (
        <Button
          color='dark'
          onClick={() =>
            setLogsModal({
              open: true,
              logs: row.original.IntCrStepStatusLogs,
              type: 'Finalize TP Flow',
            })
          }
        >
          View Logs
        </Button>
      ),
    },
    {
      accessorKey: 'fpna_logistics_sme',
      header: 'FP&A Logistics SME',
    },
    {
      accessorKey: 'fpna_gcc_sme',
      header: 'FP&A GCC SME',
    },
    {
      accessorKey: 'completed_on',
      header: 'Completed On',
    },
  ]);

  return (
    <>
      <MantineTable
        columns={columns}
        initialData={data}
        enablePinning
        initialState={initialState}
        unique={'indexId'}
        enableRowSelection={false}
        loading={loading}
        showSelectedToggle={false}
        hideSelectColumn={true}
        showResetAll={false}
        enableExport={true}
        pageSize={10}
        enableSelectAll={false}
        csvFilename={`finalize_tp_flow_${moment().format('YYYY-MM-DD')}`}

        // rightButtons={rightSideBtns}
        // leftButtons={leftButtons}
      />
    </>
  );
};

export default FinalizeTable;
