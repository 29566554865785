import { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { showAlert } from '../../../../../utils/alerts';
import FinalizeTable from './table';
import { axios } from '../../../../../utils/axios';
import { CostingRequestsContext } from '../../../../../contexts/CostingRequest';

const FinalizeTPFlow = ({ activeTab, activeSubTab, setLogsModal }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { crNumber } = useParams();
  const { crUserPermissions } = useContext(CostingRequestsContext);
  const checkAccess = (status, plant) => {
    const accessAllowed = status === '' && crUserPermissions[String(plant)][''].is_accessible;
    return accessAllowed;
  };
  useEffect(() => {
    if (activeTab === 'tpSetup' && activeSubTab === 'fobSetup') {
      //   fetch();
    }
  }, [activeTab, activeSubTab]);
  const transformData = (data) => {
    const newData = data.map((ele) => {
      ele.version = '';
      ele.control_number = '';
      ele.updated_by = '';
      ele.producing_zone = '';
      ele.producing_country = '';
      ele.manufacturing_service = '';
      ele.manufacturing_entity_name = '';
      ele.sales_org_erp = '';
      ele.manufacturing_plant = '';
      ele.buying_zone = '';

      // add all the deatils then check access
      ele.accessAllowed = checkAccess('', '');
      return ele;
    });
    return newData;
  };
  const fetch = async () => {
    setLoading(true);
    try {
      const api = `/${crNumber}`;
      const { data } = await axios.get(api);
      const apiData = transformData(data);
      setData(apiData);
    } catch (e) {
      showAlert('', 'error');
    } finally {
      setLoading(false);
    }
  };
  const handleChange = (val) => {
    setData((prevData) => prevData.map((ele) => (ele.id === val.id ? { ...ele, ...val } : ele)));
  };
  const finalizeTp = async (data) => {
    setLoading(true);
    try {
      const api = ``;
      const payload = {};
      const res = await axios.post(api, payload);
      if (res?.success) {
        showAlert('', '');
      } else {
        showAlert(res?.error, 'error');
      }
    } catch (e) {
    } finally {
      await fetch();
      setLoading(false);
    }
  };
  const initialState = {
    columnPinning: {
      //   left: ['component_description', 'component'],
      //   right: [],
    },
    sorting: [
      {
        // id: 'component_description',
        // desc: false,
      },
    ],
  };
  return (
    <>
      <FinalizeTable
        loading={loading}
        data={data}
        initialState={initialState}
        handleChange={handleChange}
        setLogsModal={setLogsModal}
        finalizeTp={finalizeTp}
      />
    </>
  );
};

export default FinalizeTPFlow;
